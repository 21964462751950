const NoAccess = () => {
  return (
    <>
      <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <p className="text-base font-semibold text-green-600">哎呀!</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            找不到網頁
          </h1>
          <p className="mt-6 text-base leading-7 text-gray-600">
            抱歉，我們找不到您要找的頁面。
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <a
              href="/home"
              className="rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
            >
              回到Home
            </a>
          </div>
        </div>
      </main>
    </>
  );
};

export default NoAccess;
