import React, { useState, useEffect } from "react";
import {
  SignUp,
  Login,
  Homepage,
  NoAccess,
  ForMobile,
  NoLogin,
  Learn,
  Plan,
  PlanBot,
  PlanMap,
  Analysis,
  Action,
  Redeem,
} from "./pages";
import { Routes, Route } from "react-router-dom";
import { UserbackProvider } from "@userback/react";
import Gleap from 'gleap';

import { init } from "commandbar";
init("42cd9a6e");

const App = () => {
  useEffect(() => {
    const loggedInUserId = "12345"; // example
    window.CommandBar.boot(loggedInUserId).then(() => {
      // ...
    });

    return () => {
      window.CommandBar.shutdown();
    };
  }, []);

  Gleap.initialize("TP5jnIPrUoaxclAwNmPaqcnqde6czU7D");

  const [token, setToken] = useState(false);

  if (token) {
    sessionStorage.setItem("token", JSON.stringify(token));
  }

  useEffect(() => {
    if (sessionStorage.getItem("token")) {
      let data = JSON.parse(sessionStorage.getItem("token"));
      setToken(data);
    }
  }, []);

  let width = window.innerWidth;

/*   if (width > 768) {
    return (
      <div>
        <Routes>
          <Route path="*" element={<ForMobile />} />
        </Routes>
      </div>
    );
  } else { */
    return (
      <div>
        <Routes>
          <Route path={"/signup"} element={<SignUp />} />
          <Route path={"/"} element={<Login setToken={setToken} />} />
          {token ? (
            <Route path={"/home"} element={<Homepage token={token} />} />
          ) : (
            <Route path={"/home"} element={<NoLogin />} />
          )}
          {token ? (
            <Route path={"/learn"} element={<Learn token={token} />} />
          ) : (
            <Route path={"/learn"} element={<NoLogin />} />
          )}
          {token ? (
            <Route path={"/plan"} element={<Plan token={token} />} />
          ) : (
            <Route path={"/plan"} element={<NoLogin />} />
          )}
          {token ? (
            <Route path={"/plan/ecobot"} element={<PlanBot token={token} />} />
          ) : (
            <Route path={"/plan/ecobot"} element={<NoLogin />} />
          )}
          {token ? (
            <Route path={"/plan/map"} element={<PlanMap token={token} />} />
          ) : (
            <Route path={"/plan/map"} element={<NoLogin />} />
          )}
          {token ? (
            <Route path={"/analytics"} element={<Analysis token={token} />} />
          ) : (
            <Route path={"/analytics"} element={<NoLogin />} />
          )}
          {token ? (
            <Route path={"/action"} element={<Action token={token} />} />
          ) : (
            <Route path={"/action"} element={<NoLogin />} />
          )}
          {token ? (
            <Route path={"/redeem"} element={<Redeem token={token} />} />
          ) : (
            <Route path={"/redeem"} element={<NoLogin />} />
          )}
          <Route path="*" element={<NoAccess />} />
        </Routes>
      </div>
    );
  }
/* }; */

export default App;
