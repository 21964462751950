import { Fragment, React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { Disclosure, Menu, Transition, RadioGroup } from "@headlessui/react";
import {
  RiHomeSmile2Fill,
  RiBookMarkFill,
  RiMap2Fill,
  RiSearch2Fill,
  RiRecycleFill,
  RiBarChart2Fill,
  RiStarSmileFill,
} from "react-icons/ri";
import { BottomNavigation } from "reactjs-bottom-navigation";
import "commandbar";
import { supabase } from "../client";

const Action = ({ token }) => {
  const navigate = useNavigate();

  function handleLogout() {
    sessionStorage.removeItem("token");
    navigate("/");
  }

  const bottomNavItems = [
    {
      title: "Home",
      onClick: () => navigate("/home"),
      icon: <RiHomeSmile2Fill />,
      activeIcon: <RiHomeSmile2Fill color="#fff" />,
    },
    {
      title: "Learn",
      onClick: () => navigate("/learn"),
      icon: <RiBookMarkFill />,
      activeIcon: <RiBookMarkFill color="#fff" />,
    },
    {
      title: "Plan",
      onClick: () => navigate("/plan"),
      icon: <RiMap2Fill />,
      activeIcon: <RiMap2Fill color="#fff" />,
    },
    {
      title: "Action",
      onClick: () => navigate("/action"),
      icon: <RiRecycleFill />,
      activeIcon: <RiRecycleFill color="#fff" />,
    },
    {
      title: "Analytics",
      onClick: () => navigate("/analytics"),
      icon: <RiBarChart2Fill />,
      activeIcon: <RiBarChart2Fill color="#fff" />,
    },
    {
      title: "Redeem",
      onClick: () => navigate("/redeem"),
      icon: <RiStarSmileFill />,
      activeIcon: <RiStarSmileFill color="#fff" />,
    },
  ];

  return (
    <>
      <Disclosure as="nav" className="bg-white shadow">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-2 sm:px-4 lg:px-8">
              <div className="flex h-16 justify-between">
                <div className="flex px-2 lg:px-0">
                  <div className="flex flex-shrink-0 items-center">
                    <button
                      className="flex items-center gap-3"
                      onClick={() => navigate(-1)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 448 512"
                        fill="currentColor"
                      >
                        <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="flex px-2 lg:px-0">
                  <div className="flex flex-shrink-0 items-center">
                    <img
                      className="block h-8 w-auto lg:hidden"
                      src="/logo512.png"
                      alt="PlanetPal"
                    />
                    <img
                      className="hidden h-8 w-auto lg:block"
                      src="/logo512.png"
                      alt="PlanetPal"
                    />
                  </div>
                </div>
                <div className="flex flex-1 items-center justify-center px-2 lg:ml-6 lg:justify-end">
                  <div className="w-full max-w-lg lg:max-w-xs">
                    <label htmlFor="search" className="sr-only">
                      Search
                    </label>
                    <div className="relative">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <RiSearch2Fill
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                      <input
                        id="search"
                        name="search"
                        className="block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Search"
                        type="search"
                        readOnly
                        onClick={() => window.CommandBar.open()}
                      />
                    </div>
                  </div>
                </div>
                <Menu>
                  <div className="flex px-2 lg:px-0">
                    <div className="flex flex-shrink-0 items-center">
                      <Menu.Button>
                        <button
                          type="button"
                          className="inline-flex w-10 h-10 rounded-full bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                          id="user-menu"
                          aria-haspopup="true"
                          aria-expanded="true"
                        >
                          <span className="sr-only">Open user menu</span>
                          <img
                            className="rounded-full h-10 w-10"
                            src="https://th.bing.com/th/id/OIP.7i35GvRSp092_L3KWHr4jgHaHv?pid=ImgDet&rs=1"
                            alt={token.user.user_metadata.full_name}
                          />
                        </button>
                      </Menu.Button>
                      <Menu.Items>
                        <Menu.Item>
                          <div
                            className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5"
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="user-menu"
                          >
                            <button
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
                              role="menuitem"
                              onClick={handleLogout}
                            >
                              Logout
                            </button>
                          </div>
                        </Menu.Item>
                      </Menu.Items>
                    </div>
                  </div>
                </Menu>
              </div>
            </div>
          </>
        )}
      </Disclosure>

      <BottomNavigation
        items={bottomNavItems}
        selected={3}
        onItemClick={(item) => console.log(item)}
        activeBgColor="green"
        activeTextColor="white"
      />

      <div className="py-8">
        <header>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold leading-tight tracking-tight text-green-600 pb-4">
            Upload Shopping Receipt / Recycling Proof
            </h1>
          </div>
        </header>
        <main>
          <form className="space-y-8 divide-y divide-gray-200 px-4">
            <div className="space-y-8 divide-y divide-gray-200">
              <div>
                <div>
                  <p className="mt-1 text-xl text-gray-800">
                  Once submitted, our team will review your submission as quickly as possible. This process can take up to three days.
                  </p>
                </div>
                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-6">
                    <label
                      htmlFor="about"
                      className="block text-lg font-medium text-gray-700"
                    >
                      What did you purchase / recycle? :)
                    </label>
                    <div className="mt-1">
                      <textarea
                        id="about"
                        name="about"
                        rows={3}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
                        defaultValue={""}
                      />
                    </div>
                    <p className="mt-2 text-sm text-gray-500">
                      Some details would be sufficient
                    </p>
                  </div>
                  <div className="sm:col-span-6">
                    <label
                      htmlFor="cover-photo"
                      className="block text-lg font-medium text-gray-700"
                    >
                      Photo / Image Proof
                    </label>
                    <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                      <div className="space-y-1 text-center">
                        <svg
                          className="mx-auto h-12 w-12 text-gray-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <div className="flex text-sm text-gray-600">
                          <label
                            htmlFor="file-upload"
                            className="relative cursor-pointer rounded-md bg-white font-medium text-emerald-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-emerald-500 focus-within:ring-offset-2 hover:text-emerald-500"
                          >
                            <span>Upload a file</span>
                            <input
                              id="file-upload"
                              name="file-upload"
                              type="file"
                              className="sr-only"
                            />
                          </label>
                          <p className="pl-1">or drag and drop</p>
                        </div>
                        <p className="text-xs text-gray-500">
                          PNG, JPG, GIF up to 10MB. Please make sure the image is in clear resolution so that our team members can review in a more responsive manner.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pt-5">
                <div className="flex justify-end">
                  <button
                    type="button"
                    className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </main>
      </div>
    </>
  );
};

export default Action;
