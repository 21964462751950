import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
// import { UserbackProvider } from "@userback/react";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import("preline");


ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <BrowserRouter>
    <head><link rel="stylesheet" href="https://use.typekit.net/ofb0enx.css"></link></head>
      {/* <UserbackProvider token="6819|85045|Q0ndwUa1VS88NrF6lm6cGXcD0lFiztFKOm1QPiIWkvEg72IXC9"> */}
      <App />
      {/* </UserbackProvider> */}
    </BrowserRouter>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
