import { Fragment, React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import {
  RiHomeSmile2Fill,
  RiBookMarkFill,
  RiMap2Fill,
  RiSearch2Fill,
  RiRecycleFill,
  RiBarChart2Fill,
  RiStarSmileFill,
  RiLeafFill,
} from "react-icons/ri";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectCards, Pagination, Navigation } from "swiper/modules";
import "swiper/css/effect-cards";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css";
import { BottomNavigation } from "reactjs-bottom-navigation";
import "commandbar";
import { supabase } from "../client";

const Homepage = ({ token }) => {
  let navigate = useNavigate();

  function handleLogout() {
    sessionStorage.removeItem("token");
    navigate("/");
  }

  const [points, setPoints] = useState([]);

  async function getPoints() {
    const { data } = await supabase
      .from("user")
      .select("points")
      .eq("id", token.user.id);
    console.log(data);
    setPoints(data);
  }

  useEffect(() => {
    getPoints();
  }, []);

  const bottomNavItems = [
    {
      title: "Home",
      onClick: () => navigate("/home"),
      icon: <RiHomeSmile2Fill />,
      activeIcon: <RiHomeSmile2Fill color="#fff" />,
    },
    {
      title: "Learn",
      onClick: () => navigate("/learn"),
      icon: <RiBookMarkFill />,
      activeIcon: <RiBookMarkFill color="#fff" />,
    },
    {
      title: "Plan",
      onClick: () => navigate("/plan"),
      icon: <RiMap2Fill />,
      activeIcon: <RiMap2Fill color="#fff" />,
    },
    {
      title: "Action",
      onClick: () => navigate("/action"),
      icon: <RiRecycleFill />,
      activeIcon: <RiRecycleFill color="#fff" />,
    },
    {
      title: "Analytics",
      onClick: () => navigate("/analytics"),
      icon: <RiBarChart2Fill />,
      activeIcon: <RiBarChart2Fill color="#fff" />,
    },
    {
      title: "Redeem",
      onClick: () => navigate("/redeem"),
      icon: <RiStarSmileFill />,
      activeIcon: <RiStarSmileFill color="#fff" />,
    },
  ];

  return (
    <>
      <Disclosure as="nav" className="bg-white shadow">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-2 sm:px-4 lg:px-8">
              <div className="flex h-16 justify-between">
                <div className="flex px-2 lg:px-0">
                  <div className="flex flex-shrink-0 items-center">
                    <img
                      className="block h-8 w-auto lg:hidden"
                      src="/logo512.png"
                      alt="PlanetPal"
                    />
                    <img
                      className="hidden h-8 w-auto lg:block"
                      src="/logo512.png"
                      alt="PlanetPal"
                    />
                  </div>
                </div>
                <div className="flex flex-1 items-center justify-center px-2 lg:ml-6 lg:justify-end">
                  <div className="w-full max-w-lg lg:max-w-xs">
                    <label htmlFor="search" className="sr-only">
                      Search
                    </label>
                    <div className="relative">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <RiSearch2Fill
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                      <input
                        id="search"
                        name="search"
                        className="block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                        placeholder="Search"
                        type="search"
                        readOnly
                        onClick={() => window.CommandBar.open()}
                      />
                    </div>
                  </div>
                </div>
                <Menu>
                  <div className="flex px-2 lg:px-0">
                    <div className="flex flex-shrink-0 items-center">
                      <Menu.Button>
                        <button
                          type="button"
                          className="inline-flex w-10 h-10 rounded-full bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                          id="user-menu"
                          aria-haspopup="true"
                          aria-expanded="true"
                        >
                          <span className="sr-only">Open user menu</span>
                          <img
                            className="rounded-full h-10 w-10"
                            src="https://th.bing.com/th/id/OIP.7i35GvRSp092_L3KWHr4jgHaHv?pid=ImgDet&rs=1"
                            alt={token.user.user_metadata.full_name}
                          />
                        </button>
                      </Menu.Button>
                      <Menu.Items>
                        <Menu.Item>
                          <div
                            className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5"
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="user-menu"
                          >
                            <button
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
                              role="menuitem"
                              onClick={handleLogout}
                            >
                              Logout
                            </button>
                          </div>
                        </Menu.Item>
                      </Menu.Items>
                    </div>
                  </div>
                </Menu>
              </div>
            </div>
          </>
        )}
      </Disclosure>

      <BottomNavigation
        items={bottomNavItems}
        selected={0}
        onItemClick={(item) => console.log(item)}
        activeBgColor="green"
        activeTextColor="white"
      />

      <div className="py-8">
        <header>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mb-4">
            <h1 className="text-3xl font-bold leading-tight tracking-tight text-green-600 pb-4">
              Welcome back, {token.user.user_metadata.full_name}! ✨
            </h1>
          </div>
        </header>
        <main>
          <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
            {" "}
            <div className="m-2">
              <Swiper
                effect={"cards"}
                grabCursor={true}
                className="mySwiper"
                loop={true}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                }}
                pagination={{
                  el: ".swiper-custom-pagination",
                  clickable: true,
                }}
                modules={[Autoplay, Pagination, EffectCards]}
              >
                <SwiperSlide>
                  <a href={"/tasks"}>
                    <img src="/home/slide_1.svg"></img>
                  </a>
                </SwiperSlide>
                <SwiperSlide>
                  <a href={"/learn"}>
                    <img src="/home/slide_2.svg"></img>
                  </a>
                </SwiperSlide>
                <SwiperSlide>
                  <a href={"/plan"}>
                    <img src="/home/slide_3.svg"></img>
                  </a>
                </SwiperSlide>
                <SwiperSlide>
                  <a href={"/action"}>
                    <img src="/home/slide_4.svg"></img>
                  </a>
                </SwiperSlide>
              </Swiper>
              <div className="swiper-custom-pagination flex justify-center items-center py-4 content-center" />
              <div className="m-2">
                <h1 className="text-2xl font-bold leading-tight tracking-tight text-gray-900 pb-4">
                  Your PalPoints Total
                </h1>
                <div className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6">
                  <dt>
                    <div className="absolute rounded-md bg-green-500 p-3">
                      <RiLeafFill
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </div>
                    <p className="ml-16 truncate text-sm font-medium text-gray-500">
                      Total
                    </p>
                  </dt>
                  <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                    <p className="text-2xl font-semibold text-gray-900">
                      {points.length > 0 ? (
                        points[0].points
                      ) : (
                        <span>Loading...</span>
                      )}
                    </p>
                    <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                      <div className="text-sm">
                        <a
                          href="/analytics"
                          className="font-medium text-green-600 hover:text-green-500"
                        >
                          See Points Details
                        </a>
                      </div>
                    </div>
                  </dd>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default Homepage;
