import { Fragment, React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { Disclosure, Menu, Transition, RadioGroup } from "@headlessui/react";
import {
  RiHomeSmile2Fill,
  RiBookMarkFill,
  RiMap2Fill,
  RiSearch2Fill,
  RiRecycleFill,
  RiBarChart2Fill,
  RiStarSmileFill,
} from "react-icons/ri";
import { BottomNavigation } from "reactjs-bottom-navigation";
import "commandbar";
import { supabase } from "../client";

const Learn = ({ token }) => {
  const navigate = useNavigate();

  function handleLogout() {
    sessionStorage.removeItem("token");
    navigate("/");
  }

  const bottomNavItems = [
    {
      title: "Home",
      onClick: () => navigate("/home"),
      icon: <RiHomeSmile2Fill />,
      activeIcon: <RiHomeSmile2Fill color="#fff" />,
    },
    {
      title: "Learn",
      onClick: () => navigate("/learn"),
      icon: <RiBookMarkFill />,
      activeIcon: <RiBookMarkFill color="#fff" />,
    },
    {
      title: "Plan",
      onClick: () => navigate("/plan"),
      icon: <RiMap2Fill />,
      activeIcon: <RiMap2Fill color="#fff" />,
    },
    {
      title: "Action",
      onClick: () => navigate("/action"),
      icon: <RiRecycleFill />,
      activeIcon: <RiRecycleFill color="#fff" />,
    },
    {
      title: "Analytics",
      onClick: () => navigate("/analytics"),
      icon: <RiBarChart2Fill />,
      activeIcon: <RiBarChart2Fill color="#fff" />,
    },
    {
      title: "Redeem",
      onClick: () => navigate("/redeem"),
      icon: <RiStarSmileFill />,
      activeIcon: <RiStarSmileFill color="#fff" />,
    },
  ];

  function RadioGroup1({ label, name, value, options, onChange, disabled }) {
    return (
      <RadioGroup
        value={value}
        onChange={onChange}
        disabled={disabled}
        name={name}
      >
        <RadioGroup.Label className="font-semibold text-gray-700">
          {label}{" "}
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">
            10 PalPoints
          </span>
        </RadioGroup.Label>
        <div className="flex flex-col gap-8 mt-8">
          {options.map((o) => (
            <RadioGroup.Option key={o.id} value={o.id}>
              {({ checked }) => (
                <div className="flex items-center justify-between">
                  <div className="mr-4">{o.caption}</div>
                  <div
                    className={`
                      flex h-5 w-5 items-center justify-center rounded-full transition-all duration-200 outline-none ring-1 cursor-pointer
                      ${!checked && !disabled ? "ring-gray-400" : ""}
                      ${checked && !disabled ? "ring-green-400" : ""} 
                      ${disabled ? "bg-gray-200 ring-gray-200" : "bg-white"}  
                    `}
                  >
                    <span
                      className={`
                        w-3 h-3 rounded-full
                        ${checked ? "scale-100" : "scale-0"}
                        ${checked && !disabled ? "bg-green-400" : "bg-gray-400"}
                        transition-transform duration-200 ease-out`}
                    />
                  </div>
                </div>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    );
  }

  async function quizSubmit() {
    if (value == "1") {
      alert("Correct! 10 PalPoints for you :).");
      const { data, error } = await supabase
        .from("user")
        .select("points")
        .eq("id", token.user.id);
      const points = data[0].points + 10;
      const { data2, error2 } = await supabase
        .from("user")
        .update({ points: points })
        .eq("id", token.user.id);

      if (error) {
        alert(error.message);
      } else {
        navigate("/learn");
      }
    }
  }

  const [value, setValue] = useState("1");

  return (
    <>
      <Disclosure as="nav" className="bg-white shadow">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-2 sm:px-4 lg:px-8">
              <div className="flex h-16 justify-between">
                <div className="flex px-2 lg:px-0">
                  <div className="flex flex-shrink-0 items-center">
                    <button
                      className="flex items-center gap-3"
                      onClick={() => navigate(-1)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 448 512"
                        fill="currentColor"
                      >
                        <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="flex px-2 lg:px-0">
                  <div className="flex flex-shrink-0 items-center">
                    <img
                      className="block h-8 w-auto lg:hidden"
                      src="/logo512.png"
                      alt="PlanetPal"
                    />
                    <img
                      className="hidden h-8 w-auto lg:block"
                      src="/logo512.png"
                      alt="PlanetPal"
                    />
                  </div>
                </div>
                <div className="flex flex-1 items-center justify-center px-2 lg:ml-6 lg:justify-end">
                  <div className="w-full max-w-lg lg:max-w-xs">
                    <label htmlFor="search" className="sr-only">
                      Search
                    </label>
                    <div className="relative">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <RiSearch2Fill
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                      <input
                        id="search"
                        name="search"
                        className="block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                        placeholder="Search"
                        type="search"
                        readOnly
                        onClick={() => window.CommandBar.open()}
                      />
                    </div>
                  </div>
                </div>
                <Menu>
                  <div className="flex px-2 lg:px-0">
                    <div className="flex flex-shrink-0 items-center">
                      <Menu.Button>
                        <button
                          type="button"
                          className="inline-flex w-10 h-10 rounded-full bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                          id="user-menu"
                          aria-haspopup="true"
                          aria-expanded="true"
                        >
                          <span className="sr-only">Open user menu</span>
                          <img
                            className="rounded-full h-10 w-10"
                            src="https://th.bing.com/th/id/OIP.7i35GvRSp092_L3KWHr4jgHaHv?pid=ImgDet&rs=1"
                            alt={token.user.user_metadata.full_name}
                          />
                        </button>
                      </Menu.Button>
                      <Menu.Items>
                        <Menu.Item>
                          <div
                            className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5"
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="user-menu"
                          >
                            <button
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
                              role="menuitem"
                              onClick={handleLogout}
                            >
                              Logout
                            </button>
                          </div>
                        </Menu.Item>
                      </Menu.Items>
                    </div>
                  </div>
                </Menu>
              </div>
            </div>
          </>
        )}
      </Disclosure>

      <BottomNavigation
        items={bottomNavItems}
        selected={1}
        onItemClick={(item) => console.log(item)}
        activeBgColor="green"
        activeTextColor="white"
      />

      <div className="py-8">
        <header>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold leading-tight tracking-tight text-green-600 pb-4">
              Today's Featured Article
            </h1>
            <h3 className="text-xl font-bold leading-tight tracking-tight text-gray-600 pb-4">
              Get PalPoints when you finish reading and answer the question below correctly.
            </h3>
          </div>
        </header>
        <main>
          <div className="px-4 text-lg">
            <p className="py-2">
            Source waste reduction and recycling are important issues in modern society and are of great significance to environmental protection and sustainable development. Waste reduction at the source refers to taking measures to reduce waste generation in aspects such as product design, production process and consumption habits. Recycling refers to the reuse of waste that has been generated and converts it into resources.
            </p>
            <p className="py-2">
            Why is waste reduction at source more important than recycling? First, reducing waste at the source can fundamentally solve the problem. Waste generation can be reduced by changing product design and manufacturing processes to reduce the use of unnecessary packaging materials, energy and resources. This will not only reduce the pressure on the environment, but also save energy and resources and achieve the goal of sustainable development.
            </p>
            <p className="py-2">
            Secondly, reducing waste at the source can reduce damage to the natural environment. After being discarded, many wastes will pollute the soil, water sources and atmosphere, and have a negative impact on ecosystems and biodiversity. By reducing waste generation, we can reduce the load on the natural environment and protect the stability and health of the ecological environment.
            </p>
          </div>
          <div className="flex flex-col bg-gray-100 mx-2 p-4 rounded-md max-w-md gap-8 rounded-md mb-6">
            <RadioGroup1
              name="name"
              label="Which of the following statements is true regarding the importance of source reduction and recycling?"
              value={value}
              onChange={(val) => setValue(val)}
              id="quiz"
              options={[
                {
                  id: "1",
                  caption: "Waste reduction at source is more important because it saves energy and promotes economic benefits.",
                },
                {
                  id: "2",
                  caption: "Recycling is more important because it directly reduces pollution and protects the environment.",
                },
                { id: "3", caption: "Waste reduction and recycling at source are equally important for environmental protection." },
                {
                  id: "4",
                  caption: "Waste reduction and recycling at source are not important in achieving sustainability.",
                },
              ]}
            />
            <button
              type="submit"
              onClick={() => quizSubmit()}
              className="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            >
              Submit
            </button>
          </div>
          <div className="mb-4">.</div>
        </main>
      </div>
    </>
  );
};

export default Learn;
