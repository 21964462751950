import { Fragment, React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { Disclosure, Menu, Transition, RadioGroup } from "@headlessui/react";
import {
  RiHomeSmile2Fill,
  RiBookMarkFill,
  RiMap2Fill,
  RiSearch2Fill,
  RiRecycleFill,
  RiBarChart2Fill,
  RiStarSmileFill,
} from "react-icons/ri";
import { BottomNavigation } from "reactjs-bottom-navigation";
import "commandbar";
import { supabase } from "../client";

const PlanBot = ({ token }) => {
  const navigate = useNavigate();

  function handleLogout() {
    sessionStorage.removeItem("token");
    navigate("/");
  }

  const bottomNavItems = [
    {
      title: "Home",
      onClick: () => navigate("/home"),
      icon: <RiHomeSmile2Fill />,
      activeIcon: <RiHomeSmile2Fill color="#fff" />,
    },
    {
      title: "Learn",
      onClick: () => navigate("/learn"),
      icon: <RiBookMarkFill />,
      activeIcon: <RiBookMarkFill color="#fff" />,
    },
    {
      title: "Plan",
      onClick: () => navigate("/plan"),
      icon: <RiMap2Fill />,
      activeIcon: <RiMap2Fill color="#fff" />,
    },
    {
      title: "Action",
      onClick: () => navigate("/action"),
      icon: <RiRecycleFill />,
      activeIcon: <RiRecycleFill color="#fff" />,
    },
    {
      title: "Analytics",
      onClick: () => navigate("/analytics"),
      icon: <RiBarChart2Fill />,
      activeIcon: <RiBarChart2Fill color="#fff" />,
    },
    {
      title: "Redeem",
      onClick: () => navigate("/redeem"),
      icon: <RiStarSmileFill />,
      activeIcon: <RiStarSmileFill color="#fff" />,
    },
  ];

  return (
    <>
      <Disclosure as="nav" className="bg-white shadow">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-2 sm:px-4 lg:px-8">
              <div className="flex h-16 justify-between">
                <div className="flex px-2 lg:px-0">
                  <div className="flex flex-shrink-0 items-center">
                    <button
                      className="flex items-center gap-3"
                      onClick={() => navigate(-1)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 448 512"
                        fill="currentColor"
                      >
                        <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="flex px-2 lg:px-0">
                  <div className="flex flex-shrink-0 items-center">
                    <img
                      className="block h-8 w-auto lg:hidden"
                      src="/logo512.png"
                      alt="PlanetPal"
                    />
                    <img
                      className="hidden h-8 w-auto lg:block"
                      src="/logo512.png"
                      alt="PlanetPal"
                    />
                  </div>
                </div>
                <div className="flex flex-1 items-center justify-center px-2 lg:ml-6 lg:justify-end">
                  <div className="w-full max-w-lg lg:max-w-xs">
                    <label htmlFor="search" className="sr-only">
                      Search
                    </label>
                    <div className="relative">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <RiSearch2Fill
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                      <input
                        id="search"
                        name="search"
                        className="block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                        placeholder="Search"
                        type="search"
                        readOnly
                        onClick={() => window.CommandBar.open()}
                      />
                    </div>
                  </div>
                </div>
                <Menu>
                  <div className="flex px-2 lg:px-0">
                    <div className="flex flex-shrink-0 items-center">
                      <Menu.Button>
                        <button
                          type="button"
                          className="inline-flex w-10 h-10 rounded-full bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                          id="user-menu"
                          aria-haspopup="true"
                          aria-expanded="true"
                        >
                          <span className="sr-only">Open user menu</span>
                          <img
                            className="rounded-full h-10 w-10"
                            src="https://th.bing.com/th/id/OIP.7i35GvRSp092_L3KWHr4jgHaHv?pid=ImgDet&rs=1"
                            alt={token.user.user_metadata.full_name}
                          />
                        </button>
                      </Menu.Button>
                      <Menu.Items>
                        <Menu.Item>
                          <div
                            className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5"
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="user-menu"
                          >
                            <button
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
                              role="menuitem"
                              onClick={handleLogout}
                            >
                              Logout
                            </button>
                          </div>
                        </Menu.Item>
                      </Menu.Items>
                    </div>
                  </div>
                </Menu>
              </div>
            </div>
          </>
        )}
      </Disclosure>

      <BottomNavigation
        items={bottomNavItems}
        selected={2}
        onItemClick={(item) => console.log(item)}
        activeBgColor="green"
        activeTextColor="white"
      />

      <div className="py-8">
        <header>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold leading-tight tracking-tight text-green-600 pb-4">
              與 EcoBot 聊天
            </h1>
          </div>
        </header>
        <main>
          <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8 py-2 rounded-md">
            <iframe
              src="https://app.livechatai.com/aibot-iframe/clneg3eg7000bmd0f61hlmhkz"
              className="rounded-md"
              width="100%"
              height="600"
              frameborder="0"
            ></iframe>
          </div>
        </main>
      </div>
    </>
  );
};

export default PlanBot;
